import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'home',
    },
  },
  {
    name: 'home',
    path: '/',
    component: () => import('@/view/home'),
    meta: {
      title: '首页',
    },
  },
  {
    name: 'search',
    path: '/search',
    component: () => import('@/view/search'),
    meta: {
      title: '搜索',
    },
  },
  {
    name: 'album',
    path: '/album',
    component: () => import('@/view/album'),
    meta: {
      title: '列表',
    },
    children: [
      {
        path: ":cid",
        component: () => import('@/view/album'),
      },
    ],
  },
  {
    name: 'player',
    path: '/player',
    component: () => import('@/view/player'),
    meta: {
      title: '播放器',
    },    
    children: [
      {
        path: ":aid/:id",
        component: () => import('@/view/player'),
      },
    ],
  },
  {
    name: 'morality',
    path: '/morality',
    component: () => import('@/view/morality'),
    meta: {
      title: '列表',
    },
    children: [
      {
        name: 'Morality',
        path: "/morality/:index",
        component: () => import('@/view/morality'),
      },
    ],
  },
  {
    name: 'moralityPlayer',
    path: '/morality/player',
    component: () => import('@/view/morality/player'),
    meta: {
      title: '播放器',
    },
    children: [
      {
        path: "/morality/player/:aid/:index",
        component: () => import('@/view/morality/player'),
      },
    ],
  },
  {
    name: 'moralityDownload',
    path: '/morality/download',
    component: () => import('@/view/morality/download'),
    meta: {
      title: '下载',
    },
    children: [
      {
        path: "/morality/player/:aid/:index",
        component: () => import('@/view/morality/player'),
      },
    ],
  },
  {
    name: 'aBook',
    path: '/abook',
    redirect: '/abook/list',
    meta: {
      title: '列表',
    },
    children: [
      {
        name: 'aBookList',
        path: "list/:aid/:index",
        component: () => import('@/view/abook'),
      },
      {
        name: 'aBookPlayer',
        path: "player/:aid/:index",
        component: () => import('@/view/abook/player'),
      },
    ],
  },
  {
    name: 'article',
    path: '/article',
    redirect: '/article/detail',
    meta: {
      title: '列表',
    },
    children: [
      {
        name: 'article',
        path: "detail/:id",
        component: () => import('@/view/article/detail'),
        meta: {
          title: '图文',
        },
      },
    ],
  },
  {
    name: 'app',
    path: '/special',
    redirect: '/special/index',
    meta: {
      title: '列表',
    },
    children: [
      {
        name: 'app',
        path: "app",
        component: () => import('@/view/special/app'),
        meta: {
          title: '慈光文化',
        },
      },
    ],
  },
  {
    name: 'redirect',
    path: '/u',
    component: () => import('@/view/redirect'),
    meta: {
      title: '跳转',
    },
    children: [
      {
        path: "/u/:url",
        component: () => import('@/view/redirect'),
      },
    ],
  },
];

const router = createRouter({
  mode: "history",
  routes,
  //history: createWebHashHistory(),
  history: createWebHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export { router };
