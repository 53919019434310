import { createStore } from "vuex";

export default createStore({
    state: {
      token: '',
      uid:'',
      device:'',
      langue:''
    },
    getters: {
      token (state) {
        return state.token
      },
      uid (state) {
        return state.uid
      },
      device (state) {
        return state.device
      },
      langue (state) {
        return state.langue
      }
    },
    mutations: {
      token (state,token) {
        state.token = token
      },
      uid (state,uid) {
        state.uid = uid
      },
      device (state,device) {
        state.device = device
      },
      langue (state,langue) {
        state.langue = langue
      }
    }
})
