import { createApp } from 'vue';
import App from './App.vue';
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import i18n from './i18n'
import { router } from './router';
import axios from "axios";
import VueAxios from "vue-axios";
import { Toast, Area, Dialog,ActionSheet,Search} from 'vant';
import 'vant/lib/index.css';
import '@/sytles/index.css';
import "@/assets/font/iconfont.js";
import "@/assets/font/iconfont.css";
import 'vant/es/dialog/style';
import 'vant/es/notify/style';
import createStore from '@/store';
import { getUrlKey, clearStorage } from './common/tools'
let token = getUrlKey('token')
let uid = getUrlKey('uid')
let langue = getUrlKey('langue')
let device = getUrlKey('device')

// token = '00af90691663d82d2b410d152012f2c2e44d4fa5'
// uid = '17'
clearStorage();
if(token != null){
    createStore.commit('token', token)
}
if(device != null){
    createStore.commit('device', device)
}
if(uid != null){
    createStore.commit('uid', uid)
}
if(langue != null){
    createStore.commit('langue', langue)
}
i18n.global.locale = createStore.getters.langue
const app = createApp(App);
app.use(VueAxios, axios);
app.use(VuePlyr, {plyr: {}});
app.use(createStore);
app.use(Search);
app.use(router);
app.use(Toast);
app.use(Area);
app.use(ActionSheet);
app.use(Dialog);
app.use(i18n);
app.mount('#app');